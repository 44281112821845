<template>
    <component :is="getIcon" :class="highlightIcon" />
</template>

<script>
import ExitPlanIcon from '@/assets/icons/form/exit_plan.svg'
import ResidentialAssessmentIcon from '@/assets/icons/form/residential-assessment.svg'
import BriefAssessmentIcon from '@/assets/icons/form/brief-assessment.svg'
import NonResidentialAssessmentIcon from '@/assets/icons/form/nonres-assessment.svg'
import TreatmentPlanIcon from '@/assets/icons/form/treatment_plan.svg'
import RecordAlertIcon from '@/assets/icons/records/icon_alerts.svg'
import RecordDocsIcon from '@/assets/icons/records/icon_docs.svg'
import RecordPhoneIcon from '@/assets/icons/records/icon_phone.svg'
import RecordMedicationsIcon from '@/assets/icons/records/icon_medications.svg'
import RecordResultsIcon from '@/assets/icons/records/icon_flask.svg'

export default {
    name: 'TypeIcon',
    components: {
        ExitPlanIcon,
        ResidentialAssessmentIcon,
        TreatmentPlanIcon,
        BriefAssessmentIcon,
        NonResidentialAssessmentIcon,
        RecordAlertIcon,
        RecordDocsIcon,
        RecordPhoneIcon,
        RecordMedicationsIcon,
        RecordResultsIcon
    },
    props: {
        rowData: {
            type: Object,
            default: () => { }
        },
        svgColour: {
            type: String,
            default: ''
        },
    },
    mounted() {
        // console.log('StatusIcon rowData', this.rowData)
    },
    computed: {
        getIcon() {
            if (this.rowData) {
                switch (this.rowData.iconName) {
                    case 'RESIDENTIAL_ASSESSMENT':
                        return 'ResidentialAssessmentIcon'
                    case 'NON_RESIDENTIAL_ASSESSMENT':
                        return 'NonResidentialAssessmentIcon'
                    case 'BRIEF_ASSESSMENT':
                        return 'BriefAssessmentIcon'
                    case 'ALERT':
                        return 'RecordAlertIcon'
                    case 'DOCUMENT':
                        return 'RecordDocsIcon'
                    case 'CONTACT_LOG':
                        return 'RecordPhoneIcon'
                    case 'MEDICATIONS':
                        return 'RecordMedicationsIcon'
                    case 'ANALYSIS_RESULT':
                        return 'RecordResultsIcon'
                    default:
                        return ''
                }
            }
            return ''
        },
        highlightIcon() {
            if (this.rowData && !this.svgColour) {
                if (this.rowData.status && this.rowData.status === 'ACTION_REQUIRED') {
                    return 'text-red-dark'
                }
                return 'text-grey-6'
            }
            return this.svgColour
        },
    },
}
</script>
